<template>
    <div>
        <Breadcrumb/>
        <b-card :title="$t('ResourcesList.Title')">
            <div>
                <table-default
                    v-if="rows.length > 0"
                    :rows="rows"
                    :fields="fields"
                    :stickyHeader="false"
                    :striped="true"
                    class="table-resources"
                >
                    <template v-slot:cell(name)="data">
                        <div class="d-flex">
                            <div
                                class="add-ellipsis"
                                :id="`tooltip-resource-name-${data.index}`"
                            >
                                <span>
                                    {{ data.item.name }}
                                </span>
                                <b-tooltip
                                    custom-class="tooltip-resource-settings"
                                    :target="`tooltip-resource-name-${data.index}`"
                                    triggers="hover blur"
                                    positioning="top"
                                    placement="top"
                                >
                                    <div class="info-tooltip-resource-settings">
                                        {{data.item.name}}
                                    </div>
                                </b-tooltip>
                            </div>
                        </div>
                    </template>

                    <template v-slot:cell(alias)="data">
                        <div
                            v-if="alias.id != data.item.id"
                            class="col-taxa-hora visualizacao"
                        >
                            <div
                                class="add-ellipsis"
                                :id="`tooltip-resource-alias-${data.index}`"
                            >
                                <span>
                                    {{ data.item.alias ? data.item.alias : data.item.name }}
                                </span>

                                <b-tooltip
                                    custom-class="tooltip-resource-settings"
                                    :target="`tooltip-resource-alias-${data.index}`"
                                    triggers="hover blur"
                                    positioning="top"
                                    placement="top"
                                >
                                    <div class="info-tooltip-resource-settings">
                                        {{ data.item.alias ? data.item.alias : data.item.name }}
                                    </div>
                                </b-tooltip>
                            </div>
                            <div
                                :class="['button-taxa edit', flagEditAlias && 'disabled']"
                                @click="editAlias(data.item.id, data.item.alias ? data.item.alias : data.item.name, 'open')"
                            >
                                <iconEditar/>
                            </div>
                        </div>

                        <div v-else  class="col-taxa-hora edicao">
                            <b-form-input
                                id="alias"
                                v-model="alias.value"
                                class="input-alias"
                                type="text"
                            />
                            <div class="col-buttons">
                                <div class="button-taxa confirm" @click="atualizaApelido()">
                                    <iconConfirmar/>
                                </div>
                                <div class="button-taxa close" @click="editAlias">
                                    <iconFechar/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:cell(production_rate)="data">
                        <div v-if="productionRate.id != data.item.id" class="col-taxa-hora visualizacao">
                            <span>{{data.item.production_rate ? data.item.production_rate : '00,00' }}</span>
                             <div :class="['button-taxa edit', flagEditProductionRate && 'disabled']" @click="editProductionRate(data.item.id, data.item.production_rate, 'open')">
                                <iconEditar/>
                            </div>

                        </div>
                        <div v-else  class="col-taxa-hora edicao">
                            <b-form-input
                                id="name"
                                v-model="productionRate.value"
                                class="input-taxa"
                                type="number"
                                inputmode="numeric"
                                @input="maskTaxa($event)"
                            />
                            <div class="col-buttons">
                                <div class="button-taxa confirm" @click="atualizaTaxa()">
                                    <iconConfirmar/>
                                </div>
                                <div class="button-taxa close" @click="editProductionRate">
                                    <iconFechar/>
                                </div>
                            </div>

                        </div>
                    </template>

                    <template v-slot:cell(request_date_time)="data">
                        <b-form-checkbox
                            :checked="data.item.request_date_time"
                            name="check-button"
                            switch
                            inline
                            @change="
                                toggleRequestDateTime(
                                    data.item.id,
                                    data.item.request_date_time
                                )
                            "
                        >
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(allow_change_resource)="data">
                        <b-form-checkbox
                            :checked="data.item.allow_change_resource"
                            name="check-button"
                            switch
                            inline
                            @change="
                                toggleAllowResource(
                                    data.item.id,
                                    data.item.allow_change_resource
                                )
                            "
                        >
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(control_by_hour)="data">
                        <b-form-checkbox
                            :checked="data.item.control_by_hour"
                            name="check-button"
                            switch
                            inline
                            @change="
                                toggleControlByHour(
                                    data.item.id,
                                    data.item.control_by_hour
                                )
                            "
                        >
                        </b-form-checkbox>
                    </template>
                </table-default>

                <div class="demo-spacing-0">
                    <b-alert
                        variant="primary"
                        :show="
                            flagShowOverlay == false && rows.length == 0
                                ? true
                                : false
                        "
                    >
                        <div class="alert-body">
                            <span>Nenhum resultado encontrado.</span>
                        </div>
                    </b-alert>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import Breadcrumb from '../../../layouts/components/Breadcrumb.vue'
import {
    BRow,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    BSidebar,
    VBToggle,
    BButton,
    BFormCheckbox,
    BTooltip
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TableDefault from "@core/components/table-default/TableDefault.vue";
import iconConfirmar from "@/assets/images/pages/finalizar-icon.svg";
import iconFechar from "@/assets/images/pages/clean-icon.svg";
import iconEditar from "@/assets/images/pages/editar-icon.svg";
import { mapMutations, mapGetters } from "vuex";
export default {
    components: {
        BRow,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BAlert,
        BDropdown,
        BDropdownItem,
        BCard,
        ToastificationContent,
        BSidebar,
        BButton,
        BFormCheckbox,
        BTooltip,
        Breadcrumb,
        TableDefault,
        iconConfirmar,
        iconFechar,
        iconEditar,
    },
    directives: {
        "b-toggle": VBToggle,
        Ripple,
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem("currentSite")),
            pageLength: 10,
            dir: false,
            currentlyStatusId: "",
            flagEditProductionRate:false,
            flagEditAlias:false,
            productionRate:{
                id:'',
                value:''
            },
            alias:{
                id:'',
                value:''
            },
            fields: [
                {
                    label: "ResourcesList.Id",
                    key: "preactor_resource_id",
                    sortable: false,
                    class: 'pr-0'
                },
                {
                    label: "ResourcesList.Name",
                    key: "name",
                    sortable: false,
                },
                {
                    label: "ResourcesList.Alias",
                    key: "alias",
                    sortable: false,
                    class: 'pr-0 pl-0'
                },
                {
                    label: "ResourcesList.DisplaySequenceNumber",
                    key: "display_sequence_number",
                    sortable: false,
                },
                {
                    label: "ResourcesList.RequestDatetime",
                    key: "request_date_time",
                    sortable: false,
                },
                {
                    label: "ResourcesList.AllowChangeResource",
                    key: "allow_change_resource",
                    sortable: false,
                },
                {
                    label: "ResourcesList.ControlByHour",
                    key: "control_by_hour",
                    sortable: false,
                },
                {
                    label: "ResourcesList.RatePerHour",
                    key: "production_rate",
                    sortable: false,
                },
            ],
            rows: [],
            searchTerm: "",
        };
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        maskTaxa($event){
            let newValue = $event;
            newValue = $event.replace('.', '');
            if(newValue.length >=4 && newValue[0] == '0') {
            newValue = newValue.substring(1);
            }
            newValue = newValue.replace(/(\d+)(\d{2})/, '$1.$2');
            this.productionRate.value = newValue;
        },
        editProductionRate(id = '', value = 0.00 , action = 'close'){
            this.productionRate.value = value
            this.productionRate.id = id
            this.flagEditProductionRate = action == 'close' ? false : true
        },
        atualizaTaxa(){
             this.$http
                .put(
                    `/api/${this.$router.currentRoute.params.prefix}/resource/${this.productionRate.id}`,
                    { production_rate: this.productionRate.value }
                )
                .then(() => {

                this.getResources()
                this.editProductionRate();
                })
                .catch(() => {
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        "An error occurred while updating your data!",
                        "danger"
                    );

                    this.getResources();


                });
        },
        editAlias(id = '', value = '', action = 'close'){
            this.alias.value = value;
            this.alias.id = id;
            this.flagEditAlias = action == 'close' ? false : true
        },
        atualizaApelido(){
             this.$http
                .put(
                    `/api/${this.$router.currentRoute.params.prefix}/resource/${this.alias.id}`,
                    { alias: this.alias.value }
                )
                .then(() => {

                this.getResources()
                this.editAlias();
                })
                .catch(() => {
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        "An error occurred while updating your data!",
                        "danger"
                    );

                    this.getResources();

                });
        },
        getResources() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/resource?site_guid=${this.currentSite.guid}`
                )
                .then((res) => {
                    this.rows = res.data;
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                });
        },
        toggleRequestDateTime(id, value) {
            this.$http
                .put(
                    `/api/${this.$router.currentRoute.params.prefix}/resource/${id}`,
                    { request_date_time: !value }
                )
                .then(() => this.getResources())
                .catch(() => {
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        "An error occurred while updating your data!",
                        "danger"
                    );
                    return this.getResources();
                });
        },
        toggleAllowResource(id, value) {
            this.$http
                .put(
                    `/api/${this.$router.currentRoute.params.prefix}/resource/${id}`,
                    { allow_change_resource: !value }
                )
                .then(() => this.getResources())
                .catch(() => {
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        "An error occurred while updating your data!",
                        "danger"
                    );
                    return this.getResources();
                });
        },
        toggleControlByHour(id, value) {
            this.$http
                .put(`/api/${this.$router.currentRoute.params.prefix}/resource/${id}`, { control_by_hour: !value })
                .then(() => this.getResources())
                .catch(() => {
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        "An error occurred while updating your data!",
                        "danger"
                    );
                    return this.getResources();
                });
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            });
        },
    },
    computed: {
        ...mapGetters('app', [
            'flagShowOverlay'
        ]),
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
                return this.dir;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false;
            return this.dir;
        },
    },
    created() {
        this.getResources();
    },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";

.overlay-mensage{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.tooltip-resource-settings {
//não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
    padding: 0;
    max-width: 100%;
    min-width: 1%;
    border-radius: 6px;
    overflow: hidden;
    .info-tooltip-resource-settings {
        padding: 8px;
        max-width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
    }
  }

  .arrow {
    &::before {
      border-top-color: #323232;
      border-bottom-color: #323232;
    }
  }
}

.table-resources{
    table {

        &::-webkit-scrollbar {
            height: 5px;             /* width of the entire scrollbar */
            border-radius: 50%;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #FBEEE8;        /* color of the tracking area */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #CFC4BE;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
        }
    }

    .add-ellipsis {
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    tbody tr td:nth-child(2),
    tbody tr td:nth-child(3) {
        max-width: 200px;
    }

    tbody tr td:nth-child(2) {
        min-width: 300px;
    }
    tbody tr td:nth-child(3) {
        min-width: 220px;
    }
    .col-taxa-hora{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .col-buttons{
            display: flex;
        }

        .button-taxa{
            border-radius: 5px;
            border: 1px solid transparent;
            svg{
                margin: 8px;
            }
            &:hover{
                cursor: pointer;
            }
            &.edit{
                background-color: transparent;
                 svg{
                    path{
                        fill: #974900;
                    }
                }
                &.disabled{
                    background-color: #ECE0DB;
                    pointer-events: none !important;
                    svg{
                        path{
                            fill: #7E7570;
                        }
                    }
                }
            }
            &.confirm{
                background-color: #974900;
                margin-right: 8px;
                &:hover{
                    background-color: #9d5815 !important;
                }
            }
            &.close{
                 svg{
                    margin: 5px 8px 8px 8px !important;
                }
                border: 1px solid #974900;
                &:hover{
                    background-color: #ffede2 !important;
                }
            }
        }

        .input-taxa{
            width: 75px;
            margin-right: 8px
        }
        .input-alias{
            width: 142px;
            margin-right: 8px
        }
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.dark-layout {
    div ::v-deep {
        .b-overlay {
            .bg-light {
                background-color: $theme-dark-body-bg !important;
            }
        }
    }
}
</style>
